<template>

  <div class="page-container">

    <div class="navbar-container">
      <!-- div class="navbar-border" -->
        <div class="navbar-logo">
            <img src="/fh_logo2_2.png">
            <div class="image-hr"></div>
        </div>
        <nav class="navbar-nav">
            <div class="navba-item small-item">Learn more</div>
            <div class="navba-item"><a href="https://fasquecastlenft.com/#op">Once in a lifetime nft opportunity</a></div>
            <div class="navba-item"><a href="https://fasquecastlenft.com/#2">NFT Holders Benefits</a></div>
            <div class="navba-item"><a href="https://fasquecastlenft.com/#about">About Fasque Castle</a></div>
            <div class="navba-item"><a href="https://fasquecastlenft.com/#hist">A distinguised history</a></div>
            <div class="navba-item"><a href="https://fasquecastlenft.com/#fil">Fasque Castle Facilities</a></div>
            <div class="navba-item"><a href="https://fasquecastlenft.com/#5">at your service</a></div>
            <div class="navba-item"><a href="https://fasquecastle.jokercharlieclub.com/">Claim your keys</a></div>
        </nav>
        <div class="navbar-button">
            <a>Claim your Key</a>
            <a id="burger" style="color: #fff">
                <div class="container">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
            </a>
        </div>
      </div>
      <div class="hidden-nav-container">
        <div class="navbar-nav hidden-nav">
          <div class="navba-item"><a href="https://fasquecastlenft.com/#op">Once in a lifetime nft opportunity</a></div>
          <div class="navba-item"><a href="https://fasquecastlenft.com/#2">NFT Holders Benefits</a></div>
          <div class="navba-item"><a href="https://fasquecastlenft.com/#about">About Fasque Castle</a></div>
          <div class="navba-item"><a href="https://fasquecastlenft.com/#hist">A distinguised history</a></div>
          <div class="navba-item"><a href="https://fasquecastlenft.com/#fil">Fasque Castle Facilities</a></div>
          <div class="navba-item"><a href="https://fasquecastlenft.com/#5">at your service</a></div>
          <div class="navba-item"><a href="https://fasquecastle.jokercharlieclub.com/">Claim your keys</a></div>
        </div>
      <!-- /div -->
    </div>
    <keep-alive>
      <router-view />
    </keep-alive>    
  </div>

</template>

<script>

// import Footer from './components/Footer.vue';

import { mapActions } from 'vuex'

export default {
  name: 'App',
  methods: {
    ...mapActions("nft", ["fetchHttpData"]),
    ...mapActions("accounts", ["initWeb3Modal"])
  },
  async created() {
    await this.fetchHttpData()
    this.initWeb3Modal()
    // this.$store.dispatch("accounts/initWeb3Modal");
    // this.$store.dispatch("accounts/ethereumListener");
  },
  mounted() {
    
  }
}
</script>
